.navs_box {
    width: 100%;
    padding: 4rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.active {
        width: 100%;
        height: 60.6667rem;
        background: rgba(51, 51, 51, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0
    }
}

.logo {
    width: 35.6rem;
    height: auto;
    display: block;
}

.navs_icon {
    width: 5.3333rem;
    height: 4.2667rem;
}

.navs_title {
    width: 100%;
    margin-top: 3.7333rem;
    padding-left: 9.2rem;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 3.7333rem;
    color: #FFFFFF;
    font-family: Source Han Sans CN;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .nav_item {
        margin-bottom: 6.5333rem;
        cursor: pointer;

        &.active {
            color: #FE6C15;
        }
    }
}