.scroll-to-top {
    position: fixed;
    bottom: 0;
    right: 10rem;
    background-color: #333;
    border-radius: .5332rem .5332rem 0 0;
    width: 6.4rem;
    height: 4.667rem;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s;
    display: flex;
    justify-content: center;
    padding-top: 2.9332rem;

    &:hover {
        background: #ff5f38;
    }
}

.scroll-to-top.visible {
    opacity: 1;
    visibility: visible;
}

.arrow {
    width:  1.0668rem;
    height: 1.0668rem;
    border-right: .2668rem solid white;
    border-top: .2668rem solid white;
    transform: rotate(-45deg);
}