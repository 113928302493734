.carousel {
    position: relative;
    width: 100%;
    height: 40.7813rem;
    overflow: hidden;
    z-index: 0;

    .arrow-box {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.5s;
        cursor: pointer;

        &.left {
            left: 1.5rem;
        }

        &.right {
            right: 1.5rem;
        }

        &.visible {
            opacity: 1;
        }
    }

    .arrow {
        &.left {
            width: .8333rem;
            height: .8333rem;
            border-bottom: .1042rem solid white;
            border-left: .1042rem solid white;
            transform: rotate(45deg);
            margin-right: -0.41665rem;
        }

        &.right {
            width: .8333rem;
            height: .8333rem;
            border-bottom: .1042rem solid white;
            border-right: .1042rem solid white;
            transform: rotate(-45deg);
            margin-left: -0.41665rem;
        }
    }

    .carousel-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 1s;
        overflow: hidden;

        &.active {
            opacity: 1;
        }
    }

    .carousel-image {
        transform: translateY(100%); // 图片初始位置
        opacity: 0; // 图片初始透明度
    }

    .slide-up {
        animation: slideUp 1s forwards; // 应用动画 - 从初始位置移动到目标位置，并逐渐显示
    }

    @keyframes slideUp {
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .homeHeaderBg {
        width: 100%;
        display: block;
    }

    .slogan {
        width: 29.6354rem;
        height: auto;
        position: absolute;
        left: 13.0729rem;
        top: 18.75rem;

    }

    .tipsText {
        position: absolute;
        right: 25.47rem;
        bottom: 8.07rem;
        font-size: 1.04rem;
        color: rgba(255, 255, 255, 0.3);
    }

    .tv {
        width: 38.3854rem;
        height: auto;
        position: absolute;
        top: 9.22rem;
        left: 49.375rem;
    }

    .redWave {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        bottom: -2.2rem;
        z-index: 1;
    }

    .download {
        width: 20.1094rem;
        position: absolute;
        left: 50%;
        margin-left: -10.0547rem;
        bottom: 3.5417rem;
        z-index: 2;
        cursor: pointer;
    }

    .tv2 {
        width: 47.0313rem;
        position: absolute;
        left: 8.5938rem;
        top: 8.2292rem;
    }

    .slogan2 {
        width: 27.3958rem;
        position: absolute;
        right: 8.9583rem;
        top: 17.1354rem;
    }

    .carousel-indicators {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        padding-left: 0;
        margin-right: 15%;
        margin-left: 15%;
        list-style: none;

        li {
            box-sizing: content-box;
            flex: 0 1 auto;
            width: 1.5625rem;
            height: .1563rem;
            margin-right: .1563rem;
            margin-left: .1563rem;
            text-indent: -52.0313rem;
            cursor: pointer;
            background-color: #fff;
            background-clip: padding-box;
            border-top: .5208rem solid transparent;
            border-bottom: .5208rem solid transparent;
            opacity: .5;
            transition: opacity 0.6s ease;

            &.active {
                opacity: 1;
            }
        }
    }
}