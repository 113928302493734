.footer_box {
    position: relative;
    width: 100%;
    z-index: 0;

    .scan_code {
        height: 60rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: #1E1E1E;
        padding: 11.81rem 4.416rem 10.3rem;
        box-sizing: border-box;

        .logo {
            width: 26.67rem;
            height: auto;
        }

        .QR_code {
            .serviceQR {
                width: 14rem;
                height: 14rem;
            }

            .xcxPng {
                width: 14rem;
                height: 14rem;
            }

            .officialQR {
                width: 14rem;
                height: 14rem;
                margin-left: .7813rem;
            }
        }
    }

    .bottom_frame {
        width: 100%;
        background-color: #111111;
        border-color: #111111;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3.86rem;
        box-sizing: border-box;

        a {
            color: #545454;
            font-size: 2.42rem;
            display: block;

            &:link {
                color: #545454;
            }

            &.beian {
                display: flex;
                align-items: center;
            }

            &.beian-record {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2.42rem;

                img {
                    width: 4.83rem;
                    margin-right: 1.21rem;
                }
            }
        }
    }
}