.lei-slideinfo {
  position: relative;
  width: 100%;
  margin-top: 5.3333rem;

  >div {
    max-width: 156rem;
    margin: 0 auto;
    padding: 0 4rem;

    >.lei-slideinfo-product {
      overflow: hidden;
    }

    >.lei-slideinfo-platform {
      overflow: hidden;
      margin: 0 -4rem;
      padding: 0 4rem 10rem;
      background: #f4f4f4;

      >.lei-style1 {
        margin: 0 -4rem 5.3333rem;
      }
    }

    .lei-slideinfo-title {
      padding: 6% 15% 0 15%;

      h2 {
        font-weight: 300;
        text-align: center;
        font-size: 5.6rem;
        margin-bottom: 0.5em;
        font-family: Raleway, Arial, Helvetica, sans-serif;
      }

      p {
        border-top: .2667rem solid #ff6600;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0rem;
        width: 100%;
        max-width: 10.6667rem;
      }
    }

    .lei-slideinfo-content {
      margin-top: 10.6667rem;
      margin-bottom: 10.6667rem;

      >.lei-slideinfo-content-pics {
        margin-top: 10.6667rem;
        margin-bottom: 5.3333rem;

        li {
          font-size: 0;
          text-align: center;
          img {
            margin: 0 0 6.6667rem;
            width: 85%;
          }
          // &.active {
          // }
        }
      }
    }

  }
}