.scroll-to-top {
    position: fixed;
    bottom: 0;
    right: 3.9063rem;
    background-color: #333;
    border-radius: .2083rem .2083rem 0 0;
    width: 2.5rem;
    height: 1.8229rem;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s;
    display: flex;
    justify-content: center;
    padding-top: 1.1458rem;

    &:hover {
        background: #ff5f38;
    }
}

.scroll-to-top.visible {
    opacity: 1;
    visibility: visible;
}

.arrow {
    width: .4167rem;
    height: .4167rem;
    border-right: .1042rem solid white;
    border-top: .1042rem solid white;
    transform: rotate(-45deg);
}