.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000;
}

.centeredImage {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}
