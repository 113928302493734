.lei-slideinfo {
  position: relative;
  width: 100%;
  //background: #f4f4f4;
  background: #fff;

  .slogan {
    font-weight: 700 !important;
    font-size: 2.5rem !important;
    margin: 5.3333rem 0 !important;
  }

  .contacts {
    margin-bottom: 6.6667rem !important;
  }

  >div {
    width: 100%;

    >.lei-slideinfo-info {
      overflow: hidden;
      font-size: 0;
    }


    .lei-slideinfo-title {
      max-width: 156rem;
      width: 100%;
      margin: 0 auto;
      padding: 10.6667rem 5rem 0;
      overflow: hidden;

      >h2 {
        font-weight: 300;
        text-align: center;
        font-size: 5.6rem;
        margin-bottom: 0.5em;
        font-family: Raleway, Arial, Helvetica, sans-serif;
      }

      >p {
        border-top: .2667rem solid #ff6600;
        margin: 0 auto 4rem;
        width: 100%;
        max-width: 10.6667rem;
      }

      >div {
        text-align: center;
        font-size: 2.9333rem;
        color: #747474;
        font-family: Raleway, Arial, Helvetica, sans-serif;
        font-weight: 500;
        letter-spacing: 0;
        font-style: normal;
        margin: 0 0 2.6667rem;
        line-height: 2.27;
      }
    }

    .lei-slideinfo-title_special {
      display: flex;
      width: 100%;
      justify-content: center;
      font-size: 2.9333rem;
      color: #747474;
      font-family: Raleway, Arial, Helvetica, sans-serif;
      font-weight: 500;
      letter-spacing: 0;
      font-style: normal;
      margin: 0 0 2.6667rem;
      line-height: 2.27;
    }


    .lei-slideinfo-content {
      max-width: 156rem;
      margin: 5.3333rem auto 0;

      >.lei-content-info {
        padding: 0 4rem 10.5rem;

        >.lei-content-infos {
          >div {
            margin: 0 auto;
            max-width: 156rem;
            flex-wrap: wrap;
            display: flex;
            justify-content: space-around;
            align-items: center;

            >a {
              width: 70%;

              img {
                margin-bottom: 10%;
                width: 100%;
                transition: transform .35s, box-shadow .35s;
                transform: perspective(133.3333rem) scale(1);
                box-shadow: 0 0 0 rgba(0, 0, 0, .4);

                &:hover {
                  transform: perspective(133.3333rem) scale(1.03);
                  box-shadow: 0 .4rem 2rem rgba(0, 0, 0, .4);
                }
              }
            }
          }
        }
      }
    }

  }

  >.lei-info2 {
    background: #f4f4f4;
    img {
      width: 100%;
      max-width: 156rem;
    }
  }
}