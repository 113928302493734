.carousel {
    position: relative;
    width: 100%;
    height: 100rem;
    overflow: hidden;
    z-index: 0;

    .carousel-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: opacity 1s;
        overflow: hidden;
    }

    .carousel-image {
        transform: translateY(100%); // 图片初始位置
        opacity: 0; // 图片初始透明度
        animation: slideUp 1s forwards; // 应用动画 - 从初始位置移动到目标位置，并逐渐显示
    }

    .lei-slidepic-bg-word {
        box-sizing: border-box;
        text-align: center;
        position: absolute;
        top: 42%;
        z-index: 2;
        width: 100%;
        padding: 0;
        font-family: Raleway, Arial, Helvetica, sans-serif;
        letter-spacing: .0521rem;

        >.lei-slidepic-bg-word-1 {
            color: rgb(255, 255, 255);
            font-size: 4.4rem;
            font-weight: 900;
            margin-bottom: 4rem;
        }

        >.lei-slidepic-bg-word-2 {
            color: rgb(255, 255, 255);
            font-size: 3.1rem;
            letter-spacing: 0;
            font-weight: 600;
            margin: 1% 0;
        }
        animation: slideUp 2s forwards 0.5s; // 依次是： 动画名称  持续时间  维持最终样式  延迟时间
    }

    @keyframes slideUp {
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .advHeaderBg {
        width: auto;
        height: 100%;
        display: block;
    }

    .tv {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        top: 38%;
    }
}