.carousel {
    position: relative;
    width: 100%;
    height: 100rem;
    overflow: hidden;
    z-index: 0;

    .carousel-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: opacity 1s;
        overflow: hidden;
    }

    .carousel-image {
        transform: translateY(100%); // 图片初始位置
        opacity: 0; // 图片初始透明度
        animation: slideUp 1s forwards; // 应用动画 - 从初始位置移动到目标位置，并逐渐显示
    }

    @keyframes slideUp {
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .aboutUsHeaderBg {
        width: auto;
        height: 100%;
        display: block;
        margin-left: -70rem;
    }

    .slogan {
        width: 80rem;
        height: auto;
        position: absolute;
        left: 50%;
        top: 48%;
        margin-left: -40rem;
    }
}