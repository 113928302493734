.lei-slideinfo {
  position: relative;
  width: 100%;
  //background: #f4f4f4;
  background: #fff;

  .slogan {
    font-weight: 700 !important;
    font-size: 0.9766rem !important;
    margin: 2.0833rem 0 !important;
  }

  .contacts {
    margin-bottom: 2.6042rem !important;
  }

  >div {
    width: 100%;
    font-size: 50px;

    >.lei-slideinfo-info {
      overflow: hidden;
      font-size: 0px;
    }


    .lei-slideinfo-title {
      width: 100%;
      padding: 4.1667rem 11.5625rem 0;
      box-sizing: border-box;
      overflow: hidden;

      >h2 {
        font-weight: 300;
        text-align: center;
        font-size: 1.88rem;
        margin-bottom: 0.5em;
        font-family: Raleway, Arial, Helvetica, sans-serif;
      }

      >p {
        border-top: .1042rem solid #ff6600;
        margin: 0 auto 1.5625rem;
        width: 100%;
        max-width: 4.1667rem;
      }

      >div {
        text-align: center;
        font-size: .94rem;
        color: #747474;
        font-family: Raleway, Arial, Helvetica, sans-serif;
        font-weight: 500;
        letter-spacing: 0;
        font-style: normal;
        margin: 0 0 1.0417rem;
        line-height: 2.27;
      }
    }

    .lei-slideinfo-title_special {
      display: flex;
      width: 100%;
      justify-content: center;
      font-size: .7813rem;
      color: #747474;
      font-family: Raleway, Arial, Helvetica, sans-serif;
      font-weight: 500;
      letter-spacing: 0;
      font-style: normal;
      margin: 0 0 1.0417rem;
      line-height: 2.27;
    }

    .lei-slideinfo-content {
      padding: 2.0833rem 11.5625rem 0;
      box-sizing: border-box;

      >.lei-content-info {

        >.lei-content-infos {
          >div {
            flex-wrap: wrap;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
              margin-bottom: 10%;
              width: 100%;
              transition: transform .35s, box-shadow .35s;
              transform: perspective(52.0833rem) scale(1);
              box-shadow: 0 0 0 rgba(0, 0, 0, .4);

              // &:hover {
              //   transform: perspective(52.0833rem) scale(1.03);
              //   box-shadow: 0 .1563rem .7813rem rgba(0, 0, 0, .4);
              // }
            }
          }
        }
      }
    }

  }

  >.lei-info2 {
    padding: 0;
    background: #f4f4f4;
  }
}