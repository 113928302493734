.navs_box {
    width: 100%;
    position: relative;
    padding: 2.5521rem 11.0417rem 1.5625rem 4.1146rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
        width: 13.9063rem;
        height: auto;
        display: block;
    }

    .navs_title {
        font-weight: 500;
        font-size: .9896rem;
        color: #858585;
        font-family: Source Han Sans CN;
    }

    .nav_item {
        margin-right: 8.3333rem;
        line-height: 2.3438rem;
        cursor: pointer;
        &.active {
            color: #FF5A04;
        }
    }

    .nav_item:hover {
        color: #FF5A04;
    }

    .nav_item:nth-last-child(1) {
        margin-right: 0;
    }
}