@media only screen and (max-width: 675px) {
    .btn-group {
      flex-direction: column;
  
      >button {
        width: 100% !important;
      }
    }
  }
  
  .lei-slideinfo {
    position: relative;
    width: 100%;
    margin-top: 40px;
  
    >div {
      margin: 0 auto;
      padding: 0 30px;
  
      >.lei-slideinfo-product {
        overflow: hidden;
  
        >div {
          max-width: 1170px;
          margin: 0 auto;
        }
  
        .btn-group {
          background: white !important;
        }
      }
  
      >.lei-slideinfo-platform {
        overflow: hidden;
        margin: 0 -30px;
        padding: 0 30px 75px;
        background: #f4f4f4;
  
        >div:last-child {
          max-width: 1170px;
          margin: 0 auto;
        }
  
        >.j-style1 {
          margin: 0 -30px 40px;
        }
      }
  
      .lei-slideinfo-title {
        padding: 6% 15% 0px 15%;
  
        h2 {
          font-weight: 300;
          text-align: center;
          font-size: 32px;
          margin-bottom: 0.5em;
          font-family: Raleway, Arial, Helvetica, sans-serif;
        }
  
        p {
          border-top: 2px solid #ff6600;
          margin-left: auto;
          margin-right: auto;
          margin-top: 0px;
          width: 100%;
          max-width: 80px;
        }
      }
  
      .lei-slideinfo-content {
        margin-top: 80px;
        margin-bottom: 80px;
  
  
        .btn-group {
          width: 100%;
          background: #f4f4f4;
          display: flex;
          justify-content: center;
          align-items: center;
  
          >button {
            width: 20%;
            background: none;
            border: none;
            color: #ff6600;
            font-size: 18px;
            padding: 15px 0px;
  
            &.active {
              color: #fff;
              background: #ff6600;
            }
  
            &:hover {
              color: #fff;
              background: #ff6600;
            }
  
            &:focus {
              box-shadow: none;
              outline: none;
            }
  
            h4 {
              margin: 0;
            }
          }
        }
  
        >.lei-slideinfo-content-pics {
          margin-top: 80px;
          margin-bottom: 40px;
  
          li {
            display: none;
            background: black;
  
            img {
              width: 100%;
            }
  
            &.active {
              display: block;
            }
          }
        }
      }
  
    }
  }