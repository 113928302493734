.lei-content {
    padding-top: 3%;
    padding-right: 3.6232rem;
    padding-bottom: 4.8309rem;
    padding-left: 3.6232rem;
  
    > .lei-content-word {
      > div {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 5.0725rem;
  
        > span {
          font-size: 5.0725rem;
          font-family: Raleway, Arial, Helvetica, sans-serif;
          font-weight: 400;
          line-height: 1.31;
          letter-spacing: .1208rem;
          font-style: normal;
        }
      }
  
      p {
        border-top: .2415rem solid #ff5f38;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        margin-bottom: 3.6232rem;
        width: 100%;
        max-width: 24.8792rem;
      }
    }
  
    > .lei-content-pics {
      margin-top: 7%;
  
      > div {
        width: 100%;
        margin: 4.8309rem 0;
        padding: 0;
  
        > img {
          width: 100%;
        }
      }
    }
  
    > .lei-content-lg {
      margin: 10.8696rem -3.6232rem 0;
  
      > .lei-content-lg-pic {
        position: relative;
        height: 68.1159rem;
        background-position: 50% 50% !important;
        background-size: cover !important;
        transform: translate3d(0, 0, 0);
        background: url("../../../../assets/images/main/home/singers-bg-1.jpg") no-repeat;
  
        > :first-child {
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
        }
  
        > :last-child {
          position: absolute;
          width: 100%;
          height: 11.9565rem;
          left: 0;
          bottom: 0;
        }
  
        svg {
          height: 12.0773rem;
        }
  
        > .lei-content-lg-pic-word {
          width: 90.5797rem;
          max-width: 100%;
          margin: 0 auto;
          height: 100%;
          overflow: hidden;
  
          > :first-child {
            display: block;
            margin: 24.1546rem auto 0;
            max-width: 90%;
            height: auto;
            width: 36.715rem;
          }
  
          > :last-child {
            display: block;
            margin: 6.0386rem auto 0;
            height: auto;
            max-width: 90%;
            width: 189.9758rem;
          }
        }
      }
    }
  
    > .lei-content-product {
      padding-top: 10.8696rem;
      padding-bottom: 6.0386rem;
  
      > .lei-content-product-title {
        overflow: hidden;
  
        > h2 {
          font-weight: 300;
          font-family: Raleway, Arial, Helvetica, sans-serif;
          text-align: center;
          font-size: 5.0725rem;
          line-height: 4.779rem;
          text-align: center;
  
          &::after {
            content: "";
            display: block;
            border-bottom: .2415rem solid #ff5f38;
            margin: 0 auto 7.2464rem;
            width: 100%;
            height: 2.4155rem;
            max-width: 24.8792rem;
          }
        }
      }
  
      > .lei-content-products {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        a {
          max-width: 90.5797rem;
          margin-bottom: 6.0386rem;
          img {
            transition: transform 0.35s, box-shadow 0.35s;
            transform: perspective(120.7729rem) scale(1);
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
  
            &:hover {
              transform: perspective(120.7729rem) scale(1.03);
              box-shadow: 0 .3623rem 1.8116rem rgba(0, 0, 0, 0.4);
            }
          }
        }
      }
  
      > .lei-content-product-more {
        display: flex;
        justify-content: center;
        align-items: center;
  
        > a {
          margin-top: 4.2271rem;
          width: 33%;
          font-family: Montserrat, Arial, Helvetica, sans-serif;
          font-weight: 400;
          letter-spacing: .2415rem;
          display: block;
          background: #ffffff;
          color: #333333;
          padding: 1.57rem 0;
          line-height: 2.0531rem;
          font-size: 3.8647rem;
          text-align: center;
          text-transform: uppercase;
          text-decoration: none;
  
          &:hover {
            text-decoration: none;
            background: #ff5f38;
            color: white;
          }
  
          &::before {
            content: "";
            display: block;
            border-bottom: .2415rem solid rgba(0, 0, 0, 0.1);
            width: 100%;
            transform: translate(-100%, 1.087rem);
          }
  
          &::after {
            content: "";
            display: block;
            border-bottom: .2415rem solid rgba(0, 0, 0, 0.1);
            width: 100%;
            transform: translate(100%, -1.087rem);
          }
        }
      }
    }
    > .lei-content-dangbei-banner {
      text-align: center;
      img {
        width: 100%;
        max-width: 79.7101rem;
      }
    }
  }
  