// 内容区域
.lei-content {
    padding-top: 3%;
    padding-right: 1.5625rem;
    padding-bottom: 2.0833rem;
    padding-left: 1.5625rem;

    >.lei-content-word {
        >div {
            text-align: center;
            margin-bottom: 0.5em;
            margin-top: 2.1875rem;

            >span {
                font-size: 1.6667rem;
                font-family: Raleway, Arial, Helvetica, sans-serif;
                font-weight: 400;
                line-height: 1.31;
                letter-spacing: .0521rem;
                font-style: normal;
            }
        }

        p {
            border-top: .1042rem solid #ff5f38;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0;
            margin-bottom: 1.5625rem;
            width: 100%;
            max-width: 10.7292rem;
        }
    }

    >.lei-content-pics {
        margin-top: 7%;

        &.container {
            width: 100%;
            padding-right: 10rem;
            padding-left: 10rem;
            box-sizing: border-box;
        }

        >div>div {
            display: inline-block;
            width: 29%;
            margin: 2%;
            padding: 0;

            >img {
                width: 100%;
            }
        }
    }

    >.lei-content-lg {
        margin: 4.6875rem -1.5625rem 0;

        >.lei-content-lg-pic {
            position: relative;
            height: 564px;
            background-position: 50% 50% !important;
            background-size: cover !important;
            transform: translate3d(0, 0, 0);
            background: url('../../../../assets/images/main/home/singers-bg-1.jpg') no-repeat;

            > :first-child {
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
            }

            > :last-child {
                position: absolute;
                width: 100%;
                height: 99px;
                left: 0;
                bottom: 0;
            }


            svg {
                height: 100px;
            }

            >.lei-content-lg-pic-word {
                width: 60.9375rem;
                max-width: 100%;
                margin: 0 auto;
                height: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                > :first-child {
                    display: block;
                    max-width: 90%;
                    height: auto;
                    width: 15.8333rem;
                }

                > :last-child {
                    display: block;
                    margin: 2.6042rem auto 0;
                    height: auto;
                    max-width: 90%;
                    width: 81.9271rem;
                }
            }
        }
    }

    >.lei-content-product {
        padding-top: 4.6875rem;
        padding-bottom: 2.6042rem;

        >.lei-content-product-title {
            overflow: hidden;

            >h2 {
                font-weight: 300;
                text-align: center;
                font-size: 1.6085rem;
                line-height: 2.0609rem;
                text-align: center;

                &::after {
                    content: "";
                    display: block;
                    border-bottom: .1042rem solid #ff5f38;
                    margin: 0 auto 3.125rem;
                    width: 100%;
                    height: 1.0417rem;
                    max-width: 10.7292rem;
                }
            }
        }

        >.lei-content-products {
            div {
                padding: 0 10rem;
                box-sizing: border-box;

                img {
                    margin-bottom: 1.6667rem;
                    transition: transform 0.35s, box-shadow 0.35s;
                    transform: perspective(52.0833rem) scale(1);
                    box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);

                    &:hover {
                        transform: perspective(52.0833rem) scale(1.03);
                        box-shadow: 0 .1563rem .7813rem rgba(0, 0, 0, 0.4);
                    }
                }
            }
        }

        >.lei-content-product-more {
            display: flex;
            justify-content: center;
            align-items: center;

            >a {
                margin-top: 1.8229rem;
                width: 30%;
                font-family: Montserrat, Arial, Helvetica, sans-serif;
                font-weight: 400;
                letter-spacing: .1042rem;
                display: block;
                background: #ffffff;
                color: #333333;
                padding: .6771rem 0;
                line-height: .8854rem;
                font-size: .7292rem;
                text-align: center;
                text-transform: uppercase;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                    background: #ff5f38;
                    color: white;
                }

                &::before {
                    content: "";
                    display: block;
                    border-bottom: .1042rem solid rgba(0, 0, 0, 0.1);
                    width: 100%;
                    transform: translate(-100%, .4688rem);
                }

                &::after {
                    content: "";
                    display: block;
                    border-bottom: .1042rem solid rgba(0, 0, 0, 0.1);
                    width: 100%;
                    transform: translate(100%, -0.4688rem);
                }
            }
        }
    }

    >.lei-content-dangbei-banner {
        text-align: center;

        img {
            width: 100%;
            max-width: 45.8333rem;
        }
    }
}