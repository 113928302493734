.footer_box {
    position: relative;
    width: 100%;
    z-index: 0;

    .scan_code {
        height: 16rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #1E1E1E;
        padding: 0 6.1979rem 0 5.9896rem;
        box-sizing: border-box;

        .logo {
            width: 20.1563rem;
            height: auto;
        }

        .QR_code {
            .serviceQR {
                width: 8rem;
                height: 8rem;
            }

            .xcxPng {
                width: 8rem;
                height: 8rem;
            }

            .officialQR {
                width: 8rem;
                height: 8rem;
                margin-left: .7813rem;
            }
        }
    }

    .bottom_frame {
        width: 100%;
        height: 3.5rem;
        background-color: #111111;
        border-color: #111111;
        display: flex;
        align-items: center;
        padding: .94rem 1.56rem .83rem;
        box-sizing: border-box;

        a {
            color: #545454;
            font-size: .73rem;

            &:link {
                color: #545454;
            }

            &:hover {
                color: #ddd;
            }

            &.beian {
                display: flex;
                align-items: center;
                margin-left: .52rem;
            }

            &.beian-record {
                display: flex;
                align-items: center;
                margin-left: .52rem;

                img {
                    width: .78rem;
                    margin-right: .26rem;
                }
            }
        }
    }
}