.layout {
    width: 100%;
    position: relative;
    .navs {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
    }
}

