.carousel {
    position: relative;
    width: 100%;
    height: 100rem;
    overflow: hidden;
    z-index: 0;

    .arrow-box {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 7.9988rem;
        height: 7.9988rem;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition: opacity 0.5s;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }
    }

    .arrow {
        &.left {
            width: 1.9324rem;
            height: 1.9324rem;
            border-bottom: .1208rem solid white;
            border-left: .1208rem solid white;
            transform: rotate(45deg);
            margin-right: -0.9662rem;
        }

        &.right {
            width: 1.9324rem;
            height: 1.9324rem;
            border-bottom: .1208rem solid white;
            border-right: .1208rem solid white;
            transform: rotate(-45deg);
            margin-left: -0.9662rem;
        }
    }

    .carousel-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 1s;
        overflow: hidden;

        &.active {
            opacity: 1;
        }
    }

    .carousel-image {
        transform: translateY(100%); // 图片初始位置
        opacity: 0; // 图片初始透明度
    }

    .slide-up {
        animation: slideUp 1s forwards; // 应用动画 - 从初始位置移动到目标位置，并逐渐显示
    }

    @keyframes slideUp {
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .homeHeaderBg {
        width: auto;
        height: 100%;
        display: block;
    }

    .homeHeaderBg2 {
        width: auto;
        height: 100%;
        display: block;
        margin-left: -94rem;
    }

    .slogan {
        width: 47.2rem;
        height: auto;
        position: absolute;
        left: 50%;
        margin-left: -23.6rem;
        top: 28.4rem;
    }

    .tv {
        width: 68.4rem;
        height: auto;
        position: absolute;
        left: 50%;
        margin-left: -34.2rem;
        top: 39.8667rem;
    }

    .tipsText {
        position: absolute;
        left: 50%;
        margin-left: -11.53rem;
        bottom: 18rem;
        font-size: 2.67rem;
        color: rgba(255, 255, 255, 0.3);
    }

    .redWave {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        bottom: -7rem;
        z-index: 1;
    }

    .download {
        width: 52.6667rem;
        position: absolute;
        left: 50%;
        margin-left: -26.35rem;
        bottom: 3.4667rem;
        z-index: 2;
    }

    .tv2 {
        width: 68.4rem;
        height: auto;
        position: absolute;
        left: 50%;
        margin-left: -34.2rem;
        top: 39.8667rem;
    }

    .slogan2 {
        width: 47.2rem;
        height: auto;
        position: absolute;
        left: 50%;
        margin-left: -23.6rem;
        top: 23.4rem;
    }
}